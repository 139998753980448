import React from 'react';
import './PrettyThings.css';
import './flexbin.css';
//import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

//var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};

/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class PrettyThings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_all:false,
      mouse_x:0,
      mouse_y:0
    }
  }
  showAll(){
    this.setState({
      show_all:true
    });
  }

  componentDidMount(){
    //document.addEventListener('mousemove', this.handle_mouse_move.bind(this));
    //window.addEventListener('scroll', this.handle_scroll.bind(this));
  }
  componentWillUnmount(){
    //document.removeEventListener('mousemove', this.handle_mouse_move);
    //window.removeEventListener('scroll', this.handle_scroll);
  }
  handle_scroll(e){
    
    var project_keys = Object.keys(this.props.pretty_data);
    var x = this.state.mouse_x;
    var y = this.state.mouse_y;

    var projects_length = (this.state.show_all?project_keys.length:8);

    for(var i = 0; i < projects_length; i++){
      var el = document.getElementById(project_keys[i]);
      var viewportOffset = el.getBoundingClientRect();
      // these are relative to the viewport, i.e. the window
      var top = viewportOffset.top;
      var left = viewportOffset.left;
      var width = el.offsetWidth;
      var height = el.offsetHeight;
      var middleX = left + width/2;
      var middleY = top + height/2;
      var xDiff = (middleX-x);
      var yDiff = (middleY-y);
      var xDist = Math.abs(middleX-x);
      var yDist = Math.abs(middleY-y);
      var x_multiple = 0;
      var y_multiple = 0;
      var xoffset = xDiff/30;
      var yoffset = yDiff/-20;
      var transform = "perspective(50cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
      el.style.transform = transform;
    }
  }
  handle_mouse_move(e){
    
    /*this.setState({
      mouse_x:e.clientX,
      mouse_y:e.clientY
    });*/

    var project_keys = Object.keys(this.props.pretty_data);
    var x = e.clientX;
    var y = e.clientY;

    var projects_length = (this.state.show_all?project_keys.length:8);

    for(var i = 0; i < projects_length; i++){
      var el = document.getElementById(project_keys[i]);
      var viewportOffset = el.getBoundingClientRect();
      // these are relative to the viewport, i.e. the window
      var top = viewportOffset.top;
      var left = viewportOffset.left;
      var width = el.offsetWidth;
      var height = el.offsetHeight;
      var middleX = left + width/2;
      var middleY = top + height/2;
      var xDiff = (middleX-x);
      var yDiff = (middleY-y);
      var xDist = Math.abs(middleX-x);
      var yDist = Math.abs(middleY-y);
      var x_multiple = 0;
      var y_multiple = 0;
      var xoffset = xDiff/30;
      var yoffset = yDiff/-20;
      var transform = "perspective(50cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
      el.style.transform = transform;

      this.setState({
        mouse_x:x,
        mouse_y:y
      });
    }
    
    /*if(this.state.case_is_hovered){
      var x = e.clientX;
      var y = e.clientY;
      var el = this.state.hovered_element;
      var viewportOffset = el.getBoundingClientRect();
      // these are relative to the viewport, i.e. the window
      var top = viewportOffset.top;
      var left = viewportOffset.left;
      var width = el.offsetWidth;
      var height = el.offsetHeight;
      var middleX = left + width/2;
      var middleY = top + height/2;
      var xoffset = (middleX-x)/-30;
      var yoffset = (middleY-y)/30;
      var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
      el.style.transform = transform;
    }*/
    
  }
  handle_case_click(e,key){
    this.props.changePage(key,e,true,"pretty");
  }
  render() {

    var project_keys = Object.keys(this.props.pretty_data);
    var see_more_visible = " hide";

    if(!this.state.show_all){
      project_keys.length = 8;
      see_more_visible = "";
    }

    var things = () => {
      var case_markup = project_keys.map((item, key) =>
        <a id={item} onClick={(e) => this.handle_case_click(e,item)} className="halftone">
          <img src={this.props.pretty_images[item+".png"]}/>
          <div className="pretty_title"><div>{this.props.pretty_data[item].Title}<br/><span>{this.props.pretty_data[item].Project_Type}</span></div></div>
        </a>
      );
      return (case_markup);
    }
      
    return <section id="pretty" className="case-studies hidden">
      <div className="page-container">
        <div className="section-intro">
          <h1 className="Title">Pretty Things ✨</h1>
          <p className="Description">A collection of various creative projects I’ve worked on over the years.</p>
        </div>
        <div className="cases">
        <div class="flexbin flexbin-margin">
        {things()}
        </div>
        </div>
        <div onClick={this.showAll.bind(this)} className={"see-more"+see_more_visible}>SHOW ALL <span>⌄</span></div>
      </div>
    </section>;


  }
}
// ✨

export default PrettyThings;



/*
BLOB EFFECT CODE

render() {

    var project_keys = Object.keys(this.props.pretty_data);
    var see_more_visible = " hide";

    if(!this.state.show_all){
      project_keys.length = 8;
      see_more_visible = "";
    }

    var things = () => {
      var case_markup = project_keys.map((item, key) =>
        <a id={item} href="#" className="halftone">
          <img src={this.props.pretty_images[item+".png"]}/>
          <div className="pretty_title"><div>{this.props.pretty_data[item].Title}<br/><span>{this.props.pretty_data[item].Project_Type}</span></div></div>
        </a>
      );
      return (case_markup);
    }
      
    return <section id="pretty" className="case-studies hidden">
      <div className="page-container">
        <div className="section-intro">
          <h1 className="Title">Pretty Things ✨</h1>
          <p className="Description">A collection of various creative projects I’ve worked on over the years.</p>
        </div>
        <div className="cases">
        <div class="flexbin flexbin-margin">
        {things()}
        </div>
        </div>
        <div onClick={this.showAll.bind(this)} className={"see-more"+see_more_visible}>SHOW ALL <span>⌄</span></div>
      </div>
    </section>;


  }

  */