import React from 'react';
import './CaseStudies.css';
//import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

//var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};

/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

//<div className="Logo" style={{backgroundImage: `url(${this.props.project_images["Mira_logo.png"]})`}}></div>

class CaseStudies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_all:false
    }
  }
  componentDidMount(){
    document.addEventListener('mousemove', this.handle_mouse_move.bind(this));
    window.addEventListener('scroll', this.handle_scroll.bind(this));
  }
  componentWillUnmount(){
    document.removeEventListener('mousemove', this.handle_mouse_move);
    window.removeEventListener('scroll', this.handle_scroll);
  }
  showAll(){
    this.setState({
      show_all:true,
      mouse_x:0,
      mouse_y:0
    });
  }
  handle_case_click(e,key){
    this.props.changePage(key,e,true,"case");
  }
  handle_scroll(e){
    
    if(!this.props.projectIsOpen){
      var project_keys = Object.keys(this.props.case_data);
      var x = e.clientX || this.state.mouse_x;
      var y = e.clientY || this.state.mouse_y;

      var projects_length = (this.state.show_all?project_keys.length:3);

      /*if(this.state.case_is_hovered){
        var el = this.state.hovered_element;
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        var left = viewportOffset.left;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        var middleX = left + width/2;
        var middleY = top + height/2;
        var xoffset = (middleX-x)/-20;
        var yoffset = (middleY-y)/20;
        var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
        el.style.transform = transform;
      }*/

      if(this.state.case_is_hovered){
        var el = this.state.hovered_element;
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        var left = viewportOffset.left;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        var middleX = left + width/2;
        var middleY = top + height/2;
        var xoffset = (middleX-x)/-35;
        var yoffset = (middleY-y)/35;
        var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
        el.style.transform = transform;
      }
      /*
      for(var i = 0; i < projects_length; i++){
        var el = document.getElementById(project_keys[i]);
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        var left = viewportOffset.left;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        var middleX = left + width/2;
        var middleY = top + height/2;
        var xDiff = (middleX-x);
        var yDiff = (middleY-y);
        var xDist = Math.abs(middleX-x);
        var yDist = Math.abs(middleY-y);
        var x_multiple = 0;
        var y_multiple = 0;
        var xoffset = (300-xDist>0&&300-yDist>0?xDiff/-30:0);
        var yoffset = (300-xDist>0&&300-yDist>0?yDiff/30:0);
        var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
        el.style.transform = transform;
      }*/

      this.setState({
        mouse_x:x,
        mouse_y:y
      });

    }
  }
  handle_mouse_move(e){
    
    /*this.setState({
      mouse_x:e.clientX,
      mouse_y:e.clientY
    });*/
    if(!this.props.projectIsOpen){
      var project_keys = Object.keys(this.props.case_data);
      var x = e.clientX || this.state.mouse_x;
      var y = e.clientY || this.state.mouse_y;

      var projects_length = (this.state.show_all?project_keys.length:3);

      if(this.state.case_is_hovered){
        var el = this.state.hovered_element;
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        var left = viewportOffset.left;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        var middleX = left + width/2;
        var middleY = top + height/2;
        var xoffset = (middleX-x)/-35;
        var yoffset = (middleY-y)/35;
        var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
        el.style.transform = transform;
      }
      /*
      for(var i = 0; i < projects_length; i++){
        var el = document.getElementById(project_keys[i]);
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        var left = viewportOffset.left;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        var middleX = left + width/2;
        var middleY = top + height/2;
        var xDiff = (middleX-x);
        var yDiff = (middleY-y);
        var xDist = Math.abs(middleX-x);
        var yDist = Math.abs(middleY-y);
        var x_multiple = 0;
        var y_multiple = 0;
        var xoffset = (300-xDist>0&&300-yDist>0?xDiff/-30:0);
        var yoffset = (300-xDist>0&&300-yDist>0?yDiff/30:0);
        var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
        el.style.transform = transform;
      }*/

      /*for(var i = 0; i < projects_length; i++){
        var el = document.getElementById(project_keys[i]);
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        var top = viewportOffset.top;
        var left = viewportOffset.left;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        var middleX = left + width/2;
        var middleY = top + height/2;
        var xDiff = (middleX-x);
        var yDiff = (middleY-y);
        var xDist = Math.abs(xDiff);
        var yDist = Math.abs(yDiff);
        var x_multiple = 0;
        var y_multiple = 0;
        var xoffset = (xDiff/-10)/(xDist/300);
        var yoffset = (yDiff/10)/(yDist/300);
        var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
        el.style.transform = transform;
      }*/

      this.setState({
        mouse_x:x,
        mouse_y:y
      });
    }

    
      


    /*
    if(this.state.case_is_hovered){
      var x = e.clientX;
      var y = e.clientY;
      var el = this.state.hovered_element;
      var viewportOffset = el.getBoundingClientRect();
      // these are relative to the viewport, i.e. the window
      var top = viewportOffset.top;
      var left = viewportOffset.left;
      var width = el.offsetWidth;
      var height = el.offsetHeight;
      var middleX = left + width/2;
      var middleY = top + height/2;
      var xoffset = (middleX-x)/-30;
      var yoffset = (middleY-y)/30;
      var transform = "perspective(10cm) rotateX("+yoffset+"deg) rotateY("+xoffset+"deg) rotateZ(0deg)";
      el.style.transform = transform;
    }*/
    
  }
  mouse_over_case(e,item){
    
    var el = document.getElementById(item);
    var case_el = e.target;

    case_el.classList.add('hovered');
    setTimeout(function(){ 
      case_el.classList.remove('hovered');
    }.bind(el), 200);

    this.setState({
      case_is_hovered:true,
      hovered_element:el
    });
    
  }
  mouse_out_case(e){
    
    var el = this.state.hovered_element;
    el.style.transform = "none";

    this.setState({
      case_is_hovered:false,
      hovered_element:false
    });
    
  }
  render() {
    
    var project_keys = Object.keys(this.props.case_data);
    var case_data = this.props.case_data;
    var see_more_visible = " hide";

    if(!this.state.show_all){
      project_keys.length = 3;
      var see_more_visible = "";
    }
    
    var cases = () => {
      var case_markup = project_keys.map((item, key) =>
        <div onMouseOver={(e) => this.mouse_over_case(e,item)} onMouseOut={(e) => this.mouse_out_case(e)} onClick={(e) => this.handle_case_click(e,item)} className="case-item" key={key}>
          <div className="halftones">
            <img id={item} className="Img" src={this.props.case_images[item+"_thumb.png"]}/>
          </div>
          <div className="Title">
            <div className="Name">{case_data[item].Name}</div>
            <div className="WorkType">{case_data[item].WorkType.join(", ")}<span className="company">{" @ "+case_data[item].Company}</span></div>
            <div className="Description">{case_data[item].Description}</div>
          </div>
        </div>
      );
      return (case_markup);
    }
      
    return <section id="case" className="case-studies hidden">
      <div className="page-container">
        <div className="section-intro">
          <h1 className="Title">Case Studies 📓</h1>
          <p className="Description">In-depth case studies on some of my longer-term projects.</p>
        </div>
        <div className="cases">
        {cases()}
        </div>
        <div onClick={this.showAll.bind(this)} className={"see-more"+see_more_visible}>SHOW ALL <span>⌄</span></div>
      </div>
    </section>;

  }
}
//📓

export default CaseStudies;
