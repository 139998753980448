import React from 'react';
import './PrettyViewer.css';
import './flexbin.css';
import imageZoom from 'fast-image-zoom'

imageZoom({
  selector: `img[alt]:not([alt=""]):not([data-image-zoom-disabled])`,
  cb: () => {},
  exceed: false,
  padding: 20,
})

//import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

//var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};






/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class ProjectViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top:0,
      height:300,
      nav_lock:"unlocked",
      current_section:"ID_overview",
      pretty_info_visible:true
    };
  }
  componentWillReceiveProps(nextProps) {
    this.handleScroll.bind(this);
  }
  componentDidMount(){
    var element = document.getElementById('Project-scroll');
    if (typeof(element) != 'undefined' && element != null){
      element.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }
  componentWillUnmount(){
    var element = document.getElementById('Project-scroll');
    if (typeof(element) != 'undefined' && element != null){
      element.removeEventListener('scroll', this.handleScroll);
    }
  }
  handleScroll(e){
    
    if(this.props.projectIsOpen){
    var element = document.getElementById('Project-scroll');
      var top = 0;
      var height = 300;
      var nav_lock = "unlocked";
      if(element.scrollTop < 360 && element.scrollTop > 60){
        height = height - element.scrollTop+60;
        top = -60;
      }else if(element.scrollTop > 359){
        height = 0;
        top = -60;
        nav_lock = "locked";
      }else{
        top = -element.scrollTop;
      }

      var new_section = "ID_overview";

      var elements = document.querySelectorAll('.Project-section-outer');
      if(elements){
        for (var i = 0; i < elements.length; i++) {
          var this_element = elements[i];
          //var positionFromTop = elements[i].getBoundingClientRect().top;
  
          if(element.scrollTop > this_element.offsetTop-250){
            new_section = this_element.id;
          }
        }
      }
      

      this.setState({
        current_section:new_section,
        height:height,
        top:top,
        nav_lock:nav_lock
      });
    }else{
      var element = document.getElementById('Project-scroll');
      element.removeEventListener('scroll', this.handleScroll);
    }
  }
  scrollToSection(section,e){
    var elmnt = document.getElementById(section);
    elmnt.scrollIntoView({behavior: "smooth"});
  }
  toggle_info(e){
    this.setState({
      pretty_info_visible:!this.state.pretty_info_visible
    });
  }
  renderCase(){

    if(!this.props.project_data || !this.props.prettyIsOpen){
      return false;
    }
    
    if(this.props.project_data.Type == "pretty"){

      var project_data = this.props.project_data;


      

      var hero_content = '';

      if(project_data.hasOwnProperty('Video_ID')){
        hero_content = (
          <iframe className="hero_content" width="100%" height="520" src={"https://www.youtube.com/embed/"+project_data.Video_ID} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        );
      }else{
        hero_content = (
          <img alt="image" className="hero_content" width="100%" height="auto" src={project_data.Project_Img}/>
        );
      }

      return (
      <div className="Pretty-container">
        <div className="close_pretty_button" onClick={(e) => this.props.closeProject(e)}><div className="button_inner"><div className="left"></div><div className="right"></div></div></div>
        <div className={"pretty_info_button"+" visible_"+this.state.pretty_info_visible} onClick={(e) => this.toggle_info(e)}><div className="button_inner">i</div></div>
        <div className={"Info"+" visible_"+this.state.pretty_info_visible}>
          <div className="Title Main_title">{project_data.Title}</div>
          <div className="Description">{project_data.Description}</div>
          <div className="Caption_Triangle"></div>
          <div className="Minimize" onClick={(e) => this.toggle_info(e)}>–</div>
        </div>
        {hero_content}
      </div>);
    }
  }
  render() {
    
    /*
    if(!this.props.project_data){
      return false;
    }

    if(this.props.project_data.hasOwnProperty('CaseStudy')){
      console.log(this.props.project_data.CaseStudy);
    }
    */

    

    //<img src={"./img/projects/"+this.props.current_project+"/"+item}/>
    

    if(this.props.project_is_opening){
      this.setState({
        top:0,
        height:300,
        nav_lock:"unlocked",
        current_section:"ID_overview"
      },this.props.project_is_done_opening.bind(this));
    }
    



/*<div className="Background" onClick={(e) => this.props.closeProject(e)}></div>*/

    return <div id="Project-scroll" className={"Project-viewer Pretty_BG "+this.props.prettyIsOpen}>
      <div id="project-wrap">
        {this.renderCase()}
      </div>
    </div>;
  }
}


export default ProjectViewer;
