import React from 'react';
import './Contact.css';
import Portrait from './img/christopher_portrait.png';
//import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

//var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};

/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class Contact extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
      
    return <section id="contact" className="hidden">
      <div className="page-container">
        <div className="section-intro">
          <h1 className="Title">Get In Touch 📬</h1>
          <p className="Description">I love discussing new projects! If you've got something you're working on and want to run it by me, shoot me a message below.</p>
          <form action="https://formspree.io/f/xbjqeenv" method="post" className="Contact-form">
              <input type="text" name="Name" placeholder="Name" required/>
              <input type="email" name="Email" placeholder="Email" required/>
              <textarea name="message" placeholder="Your message..." required></textarea>
              <input type="submit" value="Send it"/>
          </form>
        </div>
      </div>
      <div id = "foot_info">Designed with ❤️ in California</div>
    </section>;

  }
}
// <icon>🌻🌻</icon>
//<input type="phone" name="Phone" placeholder="Phone"/>
//<input type="text" name="Company" placeholder="Company"/>



export default Contact;

