import React from 'react';
import './ProjectViewer.css';
import './flexbin.css';
import imageZoom from 'fast-image-zoom'

imageZoom({
  selector: `img[alt]:not([alt=""]):not([data-image-zoom-disabled])`,
  cb: () => {},
  exceed: false,
  padding: 20,
})

//import mira_logo from './img/mira-logo-mini.png';
//import './App.css';

//var mira_logo_bg = {backgroundImage: `url(${mira_logo})`};






/*function Project() {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  return (
    <div className="Project-viewer">
      
    </div>
  );
}*/
/*
var ProjectViewer = React.createClass({
  propTypes: {
    // the StyledButton requires a clickHandler
    clickHandler: React.PropTypes.func.Required,
    // and I guess the text can be seen as required as well
    text: React.PropTypes.string.required
  },
  render: function() {
    // as you are sure you have a clickHandler, you can just reference it directly from the props
    return <button type="button" onClick={this.props.clickHandler}>{this.props.text}</button>;
  }
});*/

class ProjectViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top:0,
      height:300,
      nav_lock:"unlocked",
      current_section:"ID_overview"
    };
  }
  componentWillReceiveProps(nextProps) {
    var ua = navigator.userAgent.toLowerCase(); 
    var is_safari = (ua.indexOf('safari') != -1 && ua.indexOf('chrome') < 0);
    if(is_safari) { 
      var myHeader = document.getElementById('Project-Viewer-Header');
      if (typeof(myHeader) != 'undefined' && myHeader != null){
        myHeader.classList.add("safari");
      }
    }
    this.handleScroll.bind(this);
  }
  componentDidMount(){
    var element = document.getElementById('Project-scroll');
    if (typeof(element) != 'undefined' && element != null){
        element.addEventListener('scroll', this.handleScroll.bind(this));
    }
  }
  componentWillUnmount(){
    var element = document.getElementById('Project-scroll');
    if (typeof(element) != 'undefined' && element != null){
      element.removeEventListener('scroll', this.handleScroll);
    }
  }
  /*safari_handleScroll(e){
    if(this.props.projectIsOpen){
      var element = document.getElementById('Project-scroll');
        var top = 0;
        var height = 300;
        var nav_lock = "unlocked";
        if(element.scrollTop < 360 && element.scrollTop > 60){
          height = height - element.scrollTop+60;
          top = -60 + element.scrollTop;
        }else if(element.scrollTop > 359){
          height = 0;
          top = -60 + element.scrollTop;;
          nav_lock = "locked";
        }else{
          top = -element.scrollTop;
        }
  
        var new_section = "ID_overview";
  
        var elements = document.querySelectorAll('.Project-section-outer');
        if(elements){
          for (var i = 0; i < elements.length; i++) {
            var this_element = elements[i];
            //var positionFromTop = elements[i].getBoundingClientRect().top;
    
            if(element.scrollTop > this_element.offsetTop-250){
              new_section = this_element.id;
            }
          }
        }
        
  
        this.setState({
          current_section:new_section,
          height:height,
          top:top,
          nav_lock:nav_lock
        });
      }else{
        var element = document.getElementById('Project-scroll');
        element.removeEventListener('scroll', this.handleScroll);
      }
  }*/
  handleScroll(e){
    
    if(this.props.projectIsOpen){
    var element = document.getElementById('Project-scroll');
      var top = 0;
      var height = 300;
      var nav_lock = "unlocked";
      if(element.scrollTop < 360 && element.scrollTop > 60){
        height = height - element.scrollTop+60;
        top = -60;
      }else if(element.scrollTop > 359){
        height = 0;
        top = -60;
        nav_lock = "locked";
      }else{
        top = -element.scrollTop;
      }

      var new_section = "ID_overview";

      var elements = document.querySelectorAll('.Project-section-outer');
      if(elements){
        for (var i = 0; i < elements.length; i++) {
          var this_element = elements[i];
          //var positionFromTop = elements[i].getBoundingClientRect().top;
  
          if(element.scrollTop > this_element.offsetTop-250){
            new_section = this_element.id;
          }
        }
      }
      

      this.setState({
        current_section:new_section,
        height:height,
        top:top,
        nav_lock:nav_lock
      });
    }else{
      var element = document.getElementById('Project-scroll');
      element.removeEventListener('scroll', this.handleScroll);
    }
  }
  scrollToSection(section,e){
    var elmnt = document.getElementById(section);
    elmnt.scrollIntoView({behavior: "smooth"});
  }
  renderCase(){

    if(!this.props.project_data || !this.props.projectIsOpen){
      return false;
    }
    
    if(this.props.project_data.Type == "case"){

      var project_data = this.props.project_data;
      var work_overview = '';

      if(project_data.hasOwnProperty('WorkSummary')){
        var items = project_data.WorkSummary.map((item, key) =>
        <li key={key}>
          <div className="List-number">{key+1}</div>
          <div className="Name">{item.Title}</div><br/>
          <div className="Description">{item.Description}</div>
        </li>);

        work_overview = (
        <div className="Half-section">
          <h2>
            Work Overview
          </h2>
          <ul className="List Sub-services">
          {items}
          </ul>
        </div>);

      }



      var hero_content = '';

      if(project_data.hasOwnProperty('VideoID')){
        hero_content = (
          <iframe className="hero_content" width="100%" height="520" src={"https://www.youtube.com/embed/"+project_data.VideoID} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        );
      }else{
        hero_content = (
          <img alt="image" className="hero_content" width="100%" height="auto" src={project_data.HeaderSrc}/>
        );
      }

      
      var case_study = '';
      
      if(project_data.hasOwnProperty('CaseStudy')){
        
        case_study = this.renderCaseSections(project_data.CaseStudy);

      }

      // style={{backgroundColor:"#"+project_data.ProjectColor}}
      /*<div className="Company">
                  <div className="Logo" style={{backgroundImage: `url(${this.props.project_images[this.props.current_project+"_logo.png"]})`}}></div>
                  <div className="CompanyName">CASE STUDY | {this.props.project_data.Company}</div>
                </div>*/

      var nav_items = project_data.WorkSummary.map((item, key) => <div className={"nav-item"+(String(this.state.current_section)==String("ID"+key)?" active":"")} onClick={this.scrollToSection.bind(this,"ID"+key)}>{item.Short}</div>);

      return (
      <div className="Project-container">
        <div className="close_project_button mobile" onClick={(e) => this.props.closeProject(e)}><div className="button_inner"><div className="left"></div><div className="right"></div></div></div>
        <div className="Project-header-placeholder">
          <div className={"Project-header-background "+this.state.nav_lock}><div className="page-container"></div><div className="cover"></div></div>
          <header id="Project-Viewer-Header" className="Project-header" style={{top:this.state.top}}>
            <div className="Project-header-inner" style={{height:this.state.height}}>
              <div className="Project-header-inner-pad">
                <div className="Title">
                  <div className="Name">{project_data.Name}</div>
                </div>
                <div className="img-wrap"><img alt="image" className="Img" src={this.props.case_images[this.props.current_project+"/"+this.props.current_project+"_t_header.png"]}/></div>
                <div className="Description">{project_data.Description}</div>
              </div>
            </div>
            <div className="Project-nav nav">
              <div className={"nav-item"+(String(this.state.current_section)=="ID_overview"?" active":"")} onClick={this.scrollToSection.bind(this,"ID_overview")}>Overview</div>
              {nav_items}
              <div className="close_project_button" onClick={(e) => this.props.closeProject(e)}><div className="button_inner"><div className="left"></div><div className="right"></div></div></div>
            </div>
          </header>
        </div>
        <div className="Project-section-outer" id="ID_overview">
            <h2 className="Project-section-title">
              <div className="Section-number-handle"></div><div className="Section-number-circle"></div>
              <div className="Project-section-number">01</div>
              <div className="Project-section-name">Project Overview</div>
            </h2>
        <section className="Project-section overview">
          {hero_content}
          <div>
        <div className="Half-section">
          <h2>
            The Brief
          </h2>
          <div className="Description">{project_data.Brief}</div>
        </div>
        {work_overview}
        </div>
        <div style={{clear:"both"}}></div>
        </section>
        </div>

        {case_study}
      </div>);
    }
  }
  renderCaseSections(case_data){
    var case_sections = case_data.sections.map((item, key) =>
    <div className="Project-section-outer" id={"ID"+key}>
            <h2 className="Project-section-title">
              <div className="Section-number-handle"></div><div className="Section-number-circle"></div>
              <div className="Project-section-number">{"0"+(key+2)}</div>
              <div className="Project-section-name">{item.WorkType}</div>
            </h2>
        <section className="Project-section">
          {this.renderSection(item.Content)}
        </section></div>);
    return case_sections;
  }
  renderSection(section_data){
    var case_section = [];
    section_data.forEach(function(item, key){

      var bookEnd = " ";
        if(item.bookEnd){
          var bookEnd = " book-end";
        }

      if(item.pageBreak){
        case_section.push(<div className={"page-break"+bookEnd}>
            <div className="group">
                <div></div><div></div><div></div>
            </div>
          </div>);
      }
      if(item.type == "text"){
        if(item.subType == "no-title" || item.subType == "sub-text" || item.subType == "pre-text"){
          case_section.push(<div className={"section-item "+item.format+" "+item.subType+" "+item.type+" "+item.fontSize}>
            <div className="Description">
               {item.text}
            </div>
          </div>);
        }else if(item.subType == "soft-title"){
          case_section.push(<div className={"section-item "+item.format+" "+item.subType+" "+item.type+" "+item.fontSize}>
            <div className="Description">
              <span className="title">{item.title}</span> <span style={{color:"#bbb"}}>///</span> {item.text}
            </div>
          </div>);
        }else if(item.subType == "hard-title"){
          case_section.push(<div className={"section-item "+item.format+" "+item.subType+" "+item.type+" "+item.fontSize}>
            <h2>{item.title}</h2>
            <div className="Description">
            {item.text}
            </div>
          </div>);
        }
      }else if(item.type == "image"){
        case_section.push(<div className={"image-zoom-wrapper section-item "+item.format+" "+item.subType+" "+item.type}><img alt="image" src={item.img} className={"section-image "+item.style}/></div>);
      }else if(item.type == "list"){
        if(item.subType == "numbered"){
          var list_items = item.list.map((list_item, list_item_key) =>
          <li>
            <div className="List-number">{list_item_key+1}</div>
            <div className="Name">{list_item.title}</div>
            <div className="Description">{list_item.text}</div>
          </li>
          );
          case_section.push(<ul className={"List "+item.format+" "+item.subType+" "+item.type}>
            {list_items}
          </ul>);
        }
      }
    });
    return case_section;
  }
  renderPretty(){
    if(!this.props.project_data){
      return false;
    }
    if(this.props.project_data.type == "pretty"){

    }
  }
  renderComingSoon(){
    if(this.props.project_data){
      if(this.props.project_data.ComingSoon){
        return <div className="case_coming_soon"><span className="go_to_contact" onClick={(e) => this.props.get_in_touch(e)}>Get in touch</span> to see the full case study for this project.</div>
      }
    }
  }
  render() {
    
    /*
    if(!this.props.project_data){
      return false;
    }

    if(this.props.project_data.hasOwnProperty('CaseStudy')){
      console.log(this.props.project_data.CaseStudy);
    }
    */

    

    //<img src={"./img/projects/"+this.props.current_project+"/"+item}/>
    

    if(this.props.project_is_opening){
      this.setState({
        top:0,
        height:300,
        nav_lock:"unlocked",
        current_section:"ID_overview"
      },this.props.project_is_done_opening.bind(this));
    }
    



/*<div className="Background" onClick={(e) => this.props.closeProject(e)}></div>*/

    return <div id="Project-scroll" className={"Project-viewer "+this.props.projectIsOpen}>
      <div id="project-wrap">
      
      <div className="page-container">
        {this.renderCase()}
        {this.renderPretty()}
        {this.renderComingSoon()}
      </div>
      </div>
    </div>;
  }
}


export default ProjectViewer;
